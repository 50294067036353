.header-container {
  background: url('./Assets/ball.jpg') no-repeat center center/cover;
  height: 70vh;
  width: 100%;
  position: relative;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5vw 3vw;
}

.logo-box {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.logo {
  width: 2.5vw;
  height: 2.5vw;
}

.logo-box>span {
  font-size: 2vw;
  font-weight: 400;
  color: #fff;
}

.action-btn {
  padding: 1vw 2.5vw;
  font-size: 1.5vw;
  border: 1px solid orangered;
  background-color: transparent;
  border-radius: 0.75vw;
  cursor: pointer;
  color: #fff;
}

.dis-pic {
  width: 3.7vw;
  height: 3.7vw;
  border-radius: 3.7vw;
}

.avatar {
  background-color: rgb(255 90 31);
  color: #fff;
  font-family: LatoBold;
  font-weight: 700;
  font-size: 2.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar>span {
  height: 3.7vw;
}

.logged {
  display: flex;
  align-items: center;
  gap: 1.5vw;
}

.log-out {
  font-size: 1.5vw;
  font-weight: 600;
  padding: 0.75vw 1.5vw;
  border: 1px solid #ffd700;
  border-radius: 0.75vw;
  color: #fff;
  cursor: pointer;
}

.search-bar {
  position: relative;
  margin: 1.5vw auto;
  width: 70vw;
}

.search-bar>input {
  width: 100%;
  padding: 0.75vw 2.97vw 0.75vw 1.5vw;
  border: none;
  font-family: SofiaProRegular;
  font-size: 1.19vw;
  line-height: 140%;
  letter-spacing: 0;
  color: #745464;
  border-radius: 10px;
}

.search-bar>input:focus {
  border: none;
  outline: none;
}

.search-bar>img {
  position: absolute;
  top: 0.75vw;
  right: 0.75vw;
}

.tags-container {
  position: absolute;
  bottom: 2vh;
  padding: 2vw;
  height: 10vh;
  display: flex;
  gap: 1vw;
  justify-content: center;
  flex-flow: wrap;
}

.tags-container>.tag {
  margin-bottom: 1.5vw;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}

.login {
  width: 50vw;
  background-color: #fff;
  height: 37vw;
  border-radius: 0.75vw;
  padding: 2.22vw 1.5vw;
  position: fixed;
  z-index: 10001;
  top: 10vh;
  left: 25vw;
}

.login.error {
  top: 5vh !important;
  height: 45vw;
}

.form-group {
  position: relative;
}

.form-group label {
  color: #510115;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  text-align: center;
  margin: 10px 0;
}
.form-group input {
  width: 100%;
  height: 4.82vw;
  border: 1px solid #745464;
  border-radius: 0.75vw;
  margin-bottom: 0.75vw;
  color: rgba(116, 84, 100, 0.60) !important;
  font-size: 1.19vw;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  padding-left: 4.45vw;
  box-sizing: border-box;
}

.form-group input:focus {
  border: 3px solid #745464;
  outline: #745464;
  background-color: #fff;
}

.form-group img {
  position: absolute;
  top: 3.7vw;
  left: 1.5vw;
}

.exit {
  text-align: right;
}

.login>p:first-of-type {
  font-size: 2vw;
  font-weight: 600;
  color: #510115;
}

.login>p:last-of-type {
  font-size: 1.3vw;
  line-height: 140%;
  letter-spacing: 0;
  color: #745464;
} 

.login>p:last-of-type span {
  text-decoration: underline;
}

.submit-btn {
  width: 100%;
  height: 4.82vw;
  border: none;
  background-color: orangered;
  border-radius: 0.75vw;
  font-size: 1.5vw;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 140%;
  margin-bottom: 1.5vw;
}

.dropzone {
  width: 80vw;
  height: 30vw;
  margin: auto;
  background-color: #ffe89d;
  text-align: center;
  border: 3px purple dashed;
  border-radius: 5px;
}

.dropzone p {
  font-size: 2vw;
  font-weight: 700;
  color: #745464;
}

.file-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2vw;
  justify-content: space-between;
  width: 80vw;
  margin: 1.5vw auto;
  padding: 1.5vw;
}

.file-item{
  position: relative;
  height: 22.24vw;
  width: 22.24vw;
}

.tag-list {
  position: absolute;
  bottom: 0.75vw;
}

.tag {
  color: #fff;
  background-color: #d4af37;
  border-radius: 5px;
  padding: 0.2vw 0.5vw;
  font-weight: 600;
  margin-left: 0.2vw;
  vertical-align: middle;
  text-align: center;
}

.file-item img {
  height: 22.24vw;
  width: 22.24vw;
  object-fit: cover;
}

.ring-wrap {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.ring-container {
  width: 5vw;
  aspect-ratio: 1;
  position: relative;
}

.ring1,
.ring2,
.ring3 {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  border: 8px solid #000;
  border-radius: 50%;
  background-color: transparent;
  animation: scale 900ms infinite;
}

.ring2 {
  animation-delay: 600ms;
}

.ring3 {
  animation-delay: 300ms;
}

.errorMsg {
  background-color: #f7aaaa;
  color: #fff;
  border-left: 4px solid #B91C1C;
  padding: 0.3vw 1vw;
}

.check-zone {
  padding: 1.5vw 3vw;
  text-align: center;
  color: #745464;
}
.check-zone p {
  font-size: 1.3vw;
  font-weight: 600;
}

.check-zone button {
  padding: 0.75vw 1.1vw;
  font-size: 1.5vw;
  border: 1px #d4af37 solid;
  color: #B91C1C;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
}

.check-zone button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px rgba(0, 0, 0, 0.5) solid;
  color: #fff;
}

@keyframes scale {
  from {
    transform: scale(0.2);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

@media(max-width: 780px) {
  .file-list {
    width: unset;
    margin: 3vw 0;
    padding: 3vw;
  }

  .file-item img,
  .file-item {
    height: 40vw;
    width: 40vw;
  }

  .header-container {
    height: 55vh;
  }

  .header {
    padding: 3vw 6vw  ;
  }

  .logo {
    width: 6vw;
    height: 6vw;
  }

  .logo-box>span {
    font-size: 5vw;
  }

  .action-btn {
    padding: 2.5vw 5vw;
    font-size: 3.5vw;
  }

  .dis-pic {
    width: 8vw;
    height: 8vw;
    border-radius: 8vw;
  }

  .avatar {
    font-size: 5.5vw;
  }

  .avatar>span {
    height: auto;
  }

  .logged {
    gap: 3vw;
  }

  .log-out {
    font-size: 3.5vw;
  }

  .search-bar {
    margin: 3vw auto;
    width: 90vw;
    position: absolute;
    top: 50%;
    left: 5vw;

  }

  .search-bar>input {
    width: 100%;
    padding: 3vw 6vw 3vw 4vw;
    font-size: 2.8vw;
    border-radius: 8px;
  }

  .search-bar>img {
    position: absolute;
    top: 3vw;
    right: 3vw;
  }

  .tags-container {
    display: none;
  }

  .login {
    width: 90vw;
    background-color: #fff;
    max-height: 100vw;
    height: 70vw;
    border-radius: 0.75vw;
    padding: 5vw 3vw;
    position: fixed;
    z-index: 40;
    top: 10vh;
    left: 2vw;
  }

  .login.error {
    height: 100vh;
  }

  .form-group label {
    font-size: 3vw;
  }

  .form-group input {
    width: 100%;
    height: 7vw;
    border-radius: 0.75vw;
    margin-bottom: 1vw;
    font-size: 2.8vw;
    padding-left: 7vw;
  }

  .form-group img {
    top: 7vw;
    left: 1.5vw;
    width: 4vw;
  }

  .login>p:first-of-type {
    font-size: 3.5vw;
  }

  .login>p:last-of-type {
  font-size: 3vw;
  }

  .submit-btn {
    height: 7vw;
    font-size: 3.2vw;
    margin: 2.5vw 0;
  }

  .dropzone {
    display: none;
  }

  .tag-list {
    bottom: 2vw;
  }

  .ring-container {
    width: 15vw;
  }

  .ring1,
.ring2,
.ring3 {
  border: 4px solid #000;
}

.errorMsg {
  padding: 2vw 0;
}

}